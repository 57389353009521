import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { WHITE, LOGO_PRIMARY_LIGHT, LOGO_SECONDARY } from '../../util/theme';
import img from '../../assets/images/conference.jpg';
import { Button } from '@material-ui/core';
import StringTable from '../../i18n/en-us/strings.json'; 
import { UnderDevelopment } from '../under-dev';

//******************************************************************************
const STRINGS = StringTable.industries.VelactoForConference;;
//******************************************************************************
const useStyles = makeStyles(theme =>
({
  container: {
    display: 'flex',
    flex: '1 0 auto',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    backgroundColor: LOGO_PRIMARY_LIGHT.css(),
    padding: 50,
    scrollMarginTop: '60px',
  },
  description:{
    flex:'0 0 auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-end',
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 100px 100%)',
    position: 'relative',
    top: 10,
    right: 45,
    backgroundColor: WHITE.css(),
    width: '40%',
    zIndex: 10,
    padding: [[10, 20, 10, 100]],
  },
  descriptionTitle: {
    flex: '0 0 auto',
    color: LOGO_SECONDARY.css(),
    fontSize: 24,
    cursor: 'default',
  },
  descriptionText: {
    flex: '1 0 auto',
    fontSize: 14,
    cursor: 'default',
    textAlign: 'right',
  },
  img: {
    flex: '0 0 auto',
    clipPath: 'polygon(0 0, calc(100% - 100px) 0, 100% 100%, 0 100%)',
    position: 'relative',
    top: -10,
    left: 100,
    objectFit: 'cover',
    width: '50%',
    zIndex: 1,
    maxHeight: 400,
  },
}));
//------------------------------------------------------------------------------
export function VelactoForConference(props)
{
  const classes = useStyles(props);
  const [devOpen, setDevOpen] = useState(false);
  return(
    <div id={'events'} className={clsx(props.className, classes.container)}>
      <img className={classes.img} src={img} alt={''}/>
      <div className={classes.description}>
        <div className={classes.descriptionTitle}>{STRINGS.title}</div>
          <p className={classes.descriptionText}>
          {STRINGS.contentL1}
          <br/>
          {STRINGS.contentL2}
          <br/>
          {STRINGS.contentL3}
          <br/>
          {STRINGS.contentL4}
          <br/>
          </p>
        <Button variant={'contained'} onClick={() => setDevOpen(true)}>
          {STRINGS.buttonName}
        </Button>
      </div>
      <UnderDevelopment open={devOpen} onClose={() => setDevOpen(false)}
        title={STRINGS.title}
        feature={'velacto-conference'}
        description={STRINGS.underDev}/>
    </div>
  );
}
//******************************************************************************