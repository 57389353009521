import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { IndustriesTop } from '../components/industries/industries-top';
import { VelactoForYoga } from '../components/industries/velacto-for-yoga';
import { VelactoForSchools } from '../components/industries/velacto-for-schools';
import { VelactoForDance } from '../components/industries/velacto-for-dance';
import { VelactoForGyms } from '../components/industries/velacto-for-gyms';
import { VelactoForMuseums } from '../components/industries/velacto-for-museums';
import { VelactoForArt } from '../components/industries/velacto-for-art';
import { VelactoForConference } from '../components/industries/velacto-for-conference';
import { VelactoForSpa } from '../components/industries/velacto-for-spa';

//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  item: {
    minHeight: '50vh',
    border: 'solid 1px blue',
  },
}));
//------------------------------------------------------------------------------
export function IndustriesPage(props)
{
  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      <IndustriesTop/>
      <VelactoForYoga/>
      <VelactoForGyms/>
      <VelactoForDance/>
      <VelactoForArt/>
      <VelactoForMuseums/>
      <VelactoForConference/>
      <VelactoForSpa/>
      <VelactoForSchools/>
    </div>
  );
}
//------------------------------------------------------------------------------
export default IndustriesPage;
//******************************************************************************